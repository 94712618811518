.Label {
  float: left;
  font-size: 12px;
  color: #6c7d5d;
}

.Image {
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  height: 600px;
}
.carousel-caption {
  width: 100%;
  height: 100%;
  left: 0 !important;
}

.carousel-caption h1 {
  text-align: center;
  padding-top: 3%;
  font-size: 5rem;
  text-transform: uppercase;
  font-weight: 100;
}
.carousel-caption h2 {
  margin-top: 5%;
  position: relative;
  left: 47%;
  font-weight: 100;
}

.carousel-caption p {
  margin-top: 5% !important;
  font-size: 1.2rem !important;
  margin-left: 20% !important;
  margin-right: 20% !important;
  font-weight: 100 !important;
  text-align: center !important;
}
@media screen and (max-width: 768px) {
  .carousel-caption p {
    margin-top: 10%;
    font-size: 1rem;
    margin-left: 22%;
  }
  .carousel-caption h1 {
    padding-top: 3%;
    margin-left: 2.5%;
    font-size: 2rem;
    text-transform: uppercase;
  }

  .carousel-caption h2 {
    margin-top: 5%;
    position: relative;
    left: 34.5%;
  }
}

@media screen and (max-width: 480px) {
  .carousel-caption h1 {
    padding-top: 20%;
    margin-left: 0;
    padding-left: 0;
    font-size: 1.5rem !important;
    text-transform: uppercase;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute !important;
  top: -50px !important;
}

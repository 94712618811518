.p {
  margin: 0px;
}

p h3 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0.5em;
  padding-bottom: 0.4em;
  margin: 0;
}

p h2 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0.5em;
  padding-bottom: 0.4em;
  margin: 0;
}

ul:nth-of-type(1) {
  list-style-position: inside;
  padding-left: 1rem;
}

div:nth-of-type(2) p {
  margin: 0px;
  line-height: 1.4em;
  padding-bottom: 0.4em;
  padding-top: 0.5em;
}

p img {
  display: block;
  margin: auto;
  width: 35vw;
}
h3 img {
  display: block;
  margin: auto;
  width: 25vw;
}
h2 img {
  display: block;
  margin: auto;
  width: 30vw;
}
@media screen and (max-width: 1680px) {
  h3:nth-of-type(1) img {
    width: 25vw;
  }
  h2:nth-of-type(1) img {
    width: 30vw;
  }
  p:nth-of-type(1) img {
    width: 35vw;
  }
}

@media screen and (max-width: 1280px) {
  h3:nth-of-type(1) img {
    width: 25vw;
  }
  p:nth-of-type(1) img {
    width: 35vw;
  }
  h2:nth-of-type(1) img {
    width: 30vw;
  }
}

@media screen and (max-width: 480px) {
  p:nth-of-type(1) img {
    width: 100%;
  }
}

div:nth-of-type(2) a {
  text-align: center;
  border: 1px solid black;
  padding-top: 0.1%;
  padding-bottom: 0.1%;
  padding-left: 0.5%;
  padding-right: 0.5%;
  color: black;
  margin: 0 auto;
  display: inline-block;
  width: max-content;
}

div:nth-of-type(2) a:hover {
  background-color: black;
  border: 1px solid white;
  color: white;
}
.carousel .carousel-control {
  visibility: hidden;
}

:focus {
  outline: none !important;
}

.eventText p:nth-of-type(n) {
  color: lime;
}
